<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12 sm6 text-left>
        <span class="mainHeader">PURCHASE SETTLEMENT</span>
      </v-flex>
      <v-flex xs12 sm6 text-right>
        <span class="mainHeader2">Supplier : {{ Sname }}</span>
      </v-flex>
      <v-flex xs12 py-6 text-left>
        <!-- @mouseover="isHovered = true" @mouseout="isHovered = false" -->
        <v-card 
          tile elevation="0"
          :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
          :flat="$route.name == 'Dashboard' ? true : false"
        >
        <!-- <div v-if="isHovered" class="hover-message" style="color:red">
        Please enter touch rate to select an item
      </div> -->
          <v-layout pa-4 v-if="check1 == true">
            <v-flex xs12 v-if="savedList">
              <v-card elevation="0" v-if="savedList.length > 0">
                <v-layout wrap>
                  <v-flex xs12>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left tablefont">
                              <!-- :disabled="check2 == false ? true : false" -->
                              <!-- <v-checkbox
                                v-model="isSelectAll"
                                hide-details
                                class="mt-0"
                                @click="markAll()"
                              ></v-checkbox> -->
                              <!-- Select -->
                            </th>
                            <th class="text-left tablefont"><b>S.No.</b></th>
                            <th class="text-left tablefont" style="min-width: 100px;">
                              <b>TOUCH RATE(%)</b>
                            </th>
                            <th class="text-left tablefont"><b>BILL.NO</b></th>
                            <!--  <th class="text-left tablefont"><b>ITEM</b></th> -->
                            <th class="text-left tablefont"><b>DESIGN</b></th>
                            <!-- <th class="text-left tablefont"><b>COUNT</b></th> -->
                            <!-- <th class="text-left tablefont">
                              <b>ITEM/WT(gm)</b>
                            </th> -->
                            <th class="text-left tablefont">
                              <b>NET.WT(gm)</b>
                            </th>
                            <!-- <th class="text-left tablefont"><b>Gross.Wt </b></th> -->
                            <!-- <th class="text-left tablefont"><b>COST(%)</b></th>
                            <th class="text-left tablefont">
                              <b>STONE PRICE(₹)</b>
                            </th>
                            <th class="text-left tablefont">
                              <b>STONE WT(gm)</b>
                            </th> -->
                            <!-- <th class="text-left tablefont"><b>TOTAL(Rs.)</b></th> -->
                            <th class="text-left tablefont">
                              <b>THK.RATE(₹)</b>
                            </th>
                           
                            <th class="text-left tablefont">
                              <b>THK.(gm)</b>
                            </th>
                            <th class="text-left tablefont">
                              <b>PENDING THK.(gm)</b>
                            </th>
                            <th class="text-left tablefont">
                              <b>STATUS</b>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(value, i) in savedList"
                            :key="i"
                            class="table"
                          >
                            <td>
                              <v-checkbox
                                :disabled="
                                  value.touchRate == '0'||value.touchRate == '' ||value.touchRate>100 || value.settleStatus == 'Settled' ? true : false
                                "
                                @click="selectThis(value.checkbox, value._id)"
                                v-model="value.checkbox"
                                hide-details
                                      color="#3F053C"
                              ></v-checkbox>
                            </td>
                            <!-- <td>{{ i + 1 }}</td> -->
                            <td>
                              <span>
                                {{ i + 1 }}
                              </span>
                            </td>
                            <td>
                              <v-text-field
                                :disabled="
                                  value.checkbox == true || value.settleStatus == 'partiallySettled' || value.settleStatus == 'Settled'? true : false
                                "
                                :rules="[maxValueRule,minValueRule]"
                                placeholder="0"
                                type="number"
                                color="#b234a9"
                                outlined style="border-color: rgba(192, 0, 250, 0.986);"
                                flat
                                @input="cal7(i)"
                                v-model="value.touchRate"
                                dense
                                hide-details="auto"
                              ></v-text-field>
                            </td>
                            <td>
                              <span v-if="value.purchaseId">{{
                                value.purchaseId.billNo
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.supplierDesignCode">{{
                                value.supplierDesignCode
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <!-- <td>
                              <span v-if="value.initialCount"> {{ value.initialCount }}</span>
                              <span v-else>-</span>
                            </td> -->
                            <!-- <td>
                              <span v-if="value.itemPerWeight">{{
                                value.itemPerWeight
                              }}</span>
                              <span v-else>-</span>
                            </td> -->
                            <td>
                              <span v-if="value.initialnetWeight">{{
                                value.initialnetWeight
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <!-- <td>
                              <span v-if="value.cost">{{ value.cost }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.stonePrice">{{
                                value.stonePrice
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.stoneWeight">{{
                                value.stoneWeight
                              }}</span>
                              <span v-else>-</span>
                            </td> -->
                            <!-- <td>
                              <span v-if="value.taxableAmount">{{
                                value.taxableAmount
                              }}</span>
                              <span v-else>-</span>
                            </td> -->

                            <td>
                              <!-- <v-text-field 
                              v-if="value.purchaseId.isLock==true"
                              :disabled="value.checkbox ==true"
                              v-model="value.thankamRate"
                placeholder="Thankam Rate"
                outlined
                append-icon="mdi-lock"
                dense
                hide-details
              >
            </v-text-field> -->
                              <v-text-field
                                :disabled="
                                  value.checkbox == true ||
                                  value.purchaseId.isLock == true || value.settleStatus == 'partiallySettled'
                                    ? true
                                    : false
                                "
                                v-model="value.thankamRate"
                                placeholder="Thankam Rate"
                                solo
                                flat
                                color="#b234a9"
                                dense
                                hide-details
                              ></v-text-field>
                            </td>
                          
                            <td>
                              <span v-if="value.thankam">{{
                                value.thankam.toFixed(2)
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td><span v-if="value.tobePaidThankam">{{ (value.tobePaidThankam).toFixed(2) }}</span>
                              <span v-else>0</span></td>
                              <!-- <td><span v-if="value.settleStatus">{{ value.settleStatus }}</span>
                              <span v-else>0</span></td> -->
                              <td>
                        <span v-if="value.settleStatus=='Settled'">Settled</span>
                        <span v-else-if="value.settleStatus=='notSettled'">Not Settled</span>
                        <span v-else-if="value.settleStatus=='partiallySettled'">Partially Settled</span>
                        <span v-else>-</span>
                      </td>
                            <td><v-btn v-if="value.settleStatus!='notSettled'" small dense color="#3F053C" dark @click="resetsettlement(value)">Edit</v-btn></td>
                            
                            
                            <!-- <v-text-field
                              :disabled="value.checkbox ==true? true: false"
                placeholder="Thankam"
                outlined
                v-model="value.thankam"
                dense
                hide-details
              ></v-text-field> -->
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-flex>
                </v-layout>
              </v-card>
              <v-card tile v-else elevation="0"
                ><v-layout wrap>
                  <v-flex xs12 text-center class="tablefont1">
                    All purchases are settled
                  </v-flex>
                </v-layout>
                </v-card
              >
            </v-flex>
          </v-layout>
          <v-layout wrap justify-end>
            <v-flex xs12 align-self-end>
              <v-layout wrap justify-end class="px-4 pb-4" v-if="flag1 == true">
                <v-flex v-if="selected.length > 0 " xs6 text-left class="subhed"
                  >Balance Thankam(gm) : {{ (prevThankam).toFixed(2) }}
                  <!-- <br/>total :{{ CalculatedBalanceThankam }} -->
                </v-flex>
                <!-- <v-flex v-if="selected.length > 0 && paymentTypes == 'Thankam'" xs6 text-left class="subhed"
                  >Acidified Thankam(gm) : {{ (prevAcidifiedThankam).toFixed(2) }}
                </v-flex> -->
                <v-flex
                  xs4
                  v-if="selected.length > 0"
                  align-self-center
                  pr-4
                  class="subhed2"
                  text-right
                >
                  Total Thankam(gm) :
                </v-flex>
                <v-flex
                  v-if="selected.length > 0"
                  xs2
                  class="subhed3"
                  text-center
                >
                  <v-card ma-4 outlined class="py-2">
                    <span v-if="totalThankam">{{
                      totalThankam.toFixed(2)
                    }}</span>
                    <span v-else>-</span>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout wrap px-4 pb-2 v-if="selected.length > 0">
            <v-flex xs2>
              <v-layout pr-2 wrap>
                <v-flex xs12>
                  <span class="subhed2">Payment Type</span>
                </v-flex>
                <v-flex xs12>
                  <v-select
                    solo
                    flat
                    style="
                      border-color: #ced1d6;
                      border-style: solid;
                      border-width: 2px;
                      border-radius: 6px !important;
                    "
                    hide-details
                    dense
                    v-model="paymentTypes"
                    :items="type"
                    label="Select Type"
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs2 v-if="paymentTypes == 'Cash'">
              <v-layout px-2 wrap>
                <v-flex xs12>
                  <span class="subhed2">Cash(₹)</span>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    autofocus
                    v-model="cash"
                    solo
                    color="#b234a9"
                    flat
                    style="
                      border-color: #ced1d6;
                      border-style: solid;
                      border-width: 2px;
                      border-radius: 6px !important;
                    "
                    @input="cal3()"
                    dense
                    hide-details
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs2 v-if="paymentTypes == 'Cash'">
              <v-layout px-2 pb-4 wrap>
                <v-flex xs12>
                  <span class="subhed2">Eq.Thankam</span>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    disabled
                    v-model="eqthankam"
                    solo
                    flat
                    style="
                      border-color: #ced1d6;
                      border-style: solid;
                      border-width: 2px;
                      border-radius: 6px !important;
                    "
                    dense
                    hide-details
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
          
            <!-- <v-flex xs2 v-if="paymentTypes == 'Thankam'">
              <v-layout px-2 pb-4 wrap>
                <v-flex xs12>
                  <span class="subhed2">Thankam</span>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                  @input="cal4()"
                    v-model="acidifiedThankam"
                    solo
                    flat
                    style="
                      border-color: #ced1d6;
                      border-style: solid;
                      border-width: 2px;
                      border-radius: 6px !important;
                    "
                    dense
                    hide-details
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs2 v-if="paymentTypes == 'Thankam'">
              <v-layout px-2 wrap>
                <v-flex xs12>
                  <span class="subhed2">Eq.Cash(₹)</span>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    autofocus
                    v-model="eqcash"
                    solo
                    disabled
                    color="#b234a9"
                    flat
                    style="
                      border-color: #ced1d6;
                      border-style: solid;
                      border-width: 2px;
                      border-radius: 6px !important;
                    "
                   
                    dense
                    hide-details
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex> -->
            <v-spacer></v-spacer>
            <v-flex xs2 align-self-end pb-4>
              <!-- v-if="pay == true && proceed == true" -->
              <v-btn
                color="#3F053C"
                class="body-2 font-weight-bold"
                dark
                @click="AddItem()"
                >Generate Bill</v-btn
              >
            </v-flex>
          </v-layout>

          <!-- <v-layout px-4 py-4 v-if="paymentDetails" wrap>
            <v-flex xs12>Payment Settled:</v-flex>
            <v-flex xs12 >

              <v-card outlined class="pa-4">
                <v-layout wrap>
                  <v-flex xs12>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left subhed"><b>NO.</b></th>
                            <th class="text-left subhed"><b>GOLD</b></th>
                            <th class="text-left subhed"><b>AMOUNT</b></th>
                            <th class="text-left subhed"><b>THANKAM</b></th>
                            <th class="text-left subhed"><b>THOUCH RATE</b></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(value1, i) in paymentDetails"
                            :key="i"
                            class="table"
                          >
                            <td>
                              <span>
                                {{ i + 1 }}
                              </span>
                            </td>
                            <td>
                              <span v-if="value1.gold">{{
                                value1.gold
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value1.payAmount">{{
                                value1.payAmount
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value1.thankam">{{
                                value1.thankam
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value1.touchRate"> {{ value1.touchRate }}</span>
                              <span v-else>-</span>
                            </td>
                           
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout> -->
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 2000,
      msg: null,
      billDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      type: ["Cash", "Thankam"],
      newarr: {
        designId: null,
        count: 0,
        initialCount:0,
        itemPerWeight: 0,
        netWeight: 0,
        initialnetWeight:0,
        cost: 0,
        stonePrice: 0,
        stoneWeight: 0,
        taxableAmount: 0,
        thankamRate: 0,
        peritemTouchrate: 0,
        peritemThankam: 0,
      },
      isSelectAll: false,
      savedList: [],
      savedListCopy: [],
      selected: [],
      check1: false,
      thankam3: null,
      gold3: null,
      sflag: true,
      paymentTypes: [],
      paytype: null,
      type1: [],
      cash: "",
      isLock: false,
      balanceThankam: 0,
      balanceThankam1: 0,
      CalculatedBalanceThankam: 0,
      eqthankam: 0,
      check3: false,
      Weight: 0,
      Price1: 0,
      touchrate: 0,
      gold: 0,
      gold1: 0,
      purchasedata: {},
      invoiceDate: null,
      invoiceDate1: null,
      newbilldate1: null,
      newbilldate: null,
      // thankamRate: localStorage.getItem("thankamRate"),
      thankamRate: 0,
      prevThankam: 0,
      checkvalue: false,
      check2: false,
      purchaseId: null,
      totalThankam: 0,
      totalmakingcharge: 0,
      totalnetweight: 0,
      totalAmount: 0,
      purchaseId2: null,
      newvalue: "",
      calresponse: {},
      newvar1: null,
      pay: false,
      proceed: false,
      payment: [
        {
          paymentType: null,
          payAmount: null,
        },
      ],
      thankamDetails: [
        {
          thankamId: "63d3a2d42d13b00bbea3a98f",
          boardingRate: null,
          thankamWeight: null,
          batchCode: null,
        },
      ],
      eqcash:"",
      acidifiedThankam:"",
      prevAcidifiedThankam:"",
      GstStat: "GST",
      paymentDetails: [],
      newtouchrate: 0,
      flag1: false,
      isHovered:false,
      Sname:"",
    };
  },

  mounted() {
    this.getData();
    
  },
  watch: {
    selected() {
      this.calthankamTotal();
    },
  },
  methods: {
    maxValueRule(value) {
      if (value > 100) {
        return "Maximum value is 100";
      }
      return true;
    },
    minValueRule: (value) => {
      if (value < 0) {
        return 'Minimum value is 1'
      } else {
        return true
      }
    },
    cal3() {
      console.log("thankamRate", this.thankamRate);
      var eqthankam1 = this.cash / this.thankamRate;
      this.eqthankam = eqthankam1.toFixed(2);
    },
    cal4() {
      console.log("thankamRate", this.thankamRate);
      var eqcash1 = this.acidifiedThankam * this.thankamRate;
      this.eqcash = eqcash1.toFixed(2);
    },
    cal7(i) {
      this.check1 = false;
      this.newtouchrate = parseFloat(this.savedList[i].touchRate);
      this.savedList[i].thankam =
        this.savedList[i].initialnetWeight * (this.newtouchrate / 100);
      console.log("value==", this.savedList[i].thankam);

      this.savedListCopy = this.savedList;
      console.log("newarray=", this.savedListCopy);
      this.savedList = this.savedListCopy;
      this.check1 = true;
    },
    markAll() {
      if (this.isSelectAll) {
        this.selected = [];
        this.sflag = false;
        for (let i = 0; i < this.savedList.length; i++) {
          this.savedList[i].checkbox = true;
          this.selected.push(this.savedList[i]._id);
        }
        console.log("markeD", this.selected);
        // console.log("Ddata=", this.isSelectAll);
        this.sflag = true;
      } else {
        this.sflag = false;
        for (let i = 0; i < this.savedList.length; i++) {
          this.savedList[i].checkbox = false;
          this.selected = [];
        }
        console.log("Rem", this.selected);
        // console.log("Ddata=", this.isSelectAll);
        this.sflag = true;
      }
    },
    selectThis(val, id) {
      this.isSelectAll = false;
      console.log("id=", id);
      if (val == true) {
        if (this.selected.length > 0) {
          if (!id.includes(this.selected)) {
            this.selected.push(id);
            console.log("ll=");
          }
        } else {
          this.selected.push(id);
        }
      } else {
        var ar = this.selected;
        this.selected = ar.filter((x) => x != id);
      }

      console.log("selected id==", this.selected);
      console.log("savedListCopy==", this.savedListCopy);
    },
    calthankamTotal() {
      var finalArray = this.savedListCopy.filter((x) =>
        this.selected.includes(x._id.toString())
      );
      console.log("array=", finalArray);
      console.log("len=", finalArray.length);

      var thankamTotal = 0;
      for (var i = 0; i < finalArray.length; i++) {
        thankamTotal += finalArray[i].thankam;
      }
      this.totalThankam = thankamTotal;
      console.log("totalThankam=", this.totalThankam);
      this.calctotal();
    },
    calctotal() {
      if(this.selected.length>0){
      axios({
        method: "Post",
        url: "/multiple/purchase/itemcalculation",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          items: this.savedListCopy,
          currentpurchaseItemId: this.selected,
          supplierId: this.$route.query.id,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.calresponse = response.data.finalcalculation;
            this.thankamRate = response.data.finalcalculation.thankamRate;
            this.totalThankam = response.data.finalcalculation.totalThankam;
            this.prevThankam = response.data.finalcalculation.balanceThankam;
            this.prevAcidifiedThankam = response.data.finalcalculation.AcidifiedThankam;

            if (this.prevThankam) {
              this.pay = true;
            }
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    }
  },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/purchase/settle/allitems",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          supplierId: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.savedList = response.data.data;
            this.savedListCopy = this.savedList;
            console.log("savedListCopy=", this.savedListCopy);
            this.Sname = this.$route.query.name;
            console.log("sname==",this.Sname)
            this.check1 = true;
            if (this.savedList.length > 0) {
              this.flag1 = true;
            } else {
              this.flag1 = false;
            }
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    AddItem() {
      axios({
        url: "/purchase/supplier/payment",
        method: "POST",
        data: {
          balanceThankam: this.balanceThankam,
          currentpurchaseItems: this.selected,
          paymentType: this.paymentTypes,
          // if type cash
          payAmount: this.cash,
          paidThankam: parseFloat(this.eqthankam),
          // if type thankam
          // thankamAmount:this.eqcash,
          // acidifiedThankam:this.acidifiedThankam,
          //
          thankamWeight: this.totalThankam,
          thankamRate: this.thankamRate,
          supplierId: this.$route.query.id,
          // paidThankam: this.CalculatedBalanceThankam,
         

        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.eqthankam = "";
            this.selected = [];
            this.paymentTypes = [];
            this.cash = 0;
            this.totalThankam = 0;
            this.thankamRate = 0;
            this.getData();

            // this.returndata = response.data.purchaseItems;
            // if(this.returndata.length>0){
            // this.check2=true;
            // }
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    thankamCal() {
      axios({
        url: "/purchaseitem/calculation",
        method: "POST",
        data: {
          items: this.savedListCopy,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            // this.savedListCopy = [];
            // this.msg = response.data.msg;
            // this.showsnackbar = true;
            // this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    setLockoff() {
      this.isLock = false;
      localStorage.setItem("isLock", this.isLock);
      console.log("lock", this.isLock);
      this.LockItem();
    },
    LockItem() {
      axios({
        url: "/purchase/lock",
        method: "POST",
        data: {
          thankamRate: this.thankamRate,
          purchaseId: this.purchaseno,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    resetsettlement(item){
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/purchase/settlement/reset",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          thankam: item.thankam,
          id: item._id,
          tobePaidThankam: item.tobePaidThankam,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            // this.paymentDetails = response.data.payment;
            this.snackbar = true;
            this.msg = response.data.msg;
            this.selected = [];
            this.paymentTypes = [];
            this.cash = 0;
            this.totalThankam = 0;
            this.thankamRate = 0;
            this.getData();
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      //  +
      // " , " +
      // hours +
      // ":" +
      // minutes +
      // " " +
      // ampm;

      return strTime;
    },
  },
};
</script>
